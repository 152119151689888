@import 'animate.css';

@tailwind base;
@tailwind components;
@tailwind utilities;

.bg-glass {
  @apply backdrop-blur-3xl;
  background-color: rgba(120, 112, 112, 0.5);
}

/* width */
.scrollbar::-webkit-scrollbar {
  width: 12px;
}

/* Track */
.scrollbar::-webkit-scrollbar-track {
  background: transparent;

}

/* Handle */
.scrollbar::-webkit-scrollbar-thumb {
  background:    #28458c;
  border-radius: 8px;

}

html {
  @apply scroll-smooth
}

.bg-gradient-light {
  background-color: rgb(63, 98, 18);
  background-image: radial-gradient(at 11% 16%, rgb(59, 130, 246) 0, transparent 43%), radial-gradient(at 77% 59%, rgb(209, 213, 219) 0, transparent 95%), radial-gradient(at 30% 70%, rgb(34, 211, 238) 0, transparent 40%), radial-gradient(at 96% 31%, rgb(129, 140, 248) 0, transparent 84%), radial-gradient(at 72% 72%, rgb(232, 121, 249) 0, transparent 30%), radial-gradient(at 54% 34%, rgb(126, 34, 206) 0, transparent 46%), radial-gradient(at 79% 86%, rgb(15, 118, 110) 0, transparent 40%), radial-gradient(at 27% 3%, rgb(14, 116, 144) 0, transparent 47%), radial-gradient(at 68% 46%, rgb(13, 148, 136) 0, transparent 19%), radial-gradient(at 71% 73%, rgb(199, 210, 254) 0, transparent 74%), radial-gradient(at 77% 65%, rgb(127, 29, 29) 0, transparent 1%);
}